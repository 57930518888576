<template>
  <div class="page-figures">
    <!-- Tutorial -->
    <TutorialSlide nameTutorial="figure" />

    <!-- Sidebar -->
    <Sidebar />

    <!-- Figures -->
    <section
      v-if="figures.length"
      id="figures"
      class="figures"
      :style="`background-image: url(${urlImg}${figureActive.figure_avatar})`"
    >
      <!-- Main Jumbotron -->
      <div class="h-100 d-flex flex-column justify-content-between">
        <!-- Navbar -->
        <Navbar isBlack />

        <main class="container">
          <div
            ref="figuresSwiper"
            v-swiper:figuresSwiper="swiperFiguresOptions"
            @transitionEnd="figureChanged"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                :key="`figure-${i}`"
                v-for="(figure, i) in figures"
              >
                <h1 class="text-uppercase overflow-word w-lg-75">
                  {{ figure.figure_name }}
                </h1>
                <h5
                  data-en="Father Of Astronomy Science"
                  data-id="Bapak Ilmu Astronomi"
                  class="text-uppercase"
                >
                  Father Of Astronomy Science
                </h5>

                <div @click="openModal(i)" class="d-inline-block mt-5">
                  <LinkArrow
                    isBlack
                    en="EXPLORE NOW"
                    id="JELAJAHI SEKARANG"
                    title="EXPLORE NOW"
                  />
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer isBlack isSmall />

        <!-- Parallax -->
        <Parallax isBlack en="FIGURES" id="TOKOH" title="FIGURES" />
      </div>
    </section>

    <!-- Figures -->
    <div v-else class="figures">
      <!-- Main Jumbotron -->
      <div class="h-100 d-flex flex-column justify-content-between">
        <!-- Navbar -->
        <Navbar isBlack />

        <main class="container">
          <div class="row">
            <div class="col">
              <b-skeleton
                animation="wave"
                width="50%"
                height="60px"
              ></b-skeleton>
              <b-skeleton
                animation="wave"
                width="30%"
                class="mt-1"
              ></b-skeleton>
              <b-skeleton
                animation="wave"
                type="button"
                class="mt-3"
              ></b-skeleton>
            </div>
          </div>
        </main>

        <Footer isBlack isSmall />

        <!-- Parallax -->
        <Parallax isBlack en="FIGURES" id="TOKOH" title="FIGURES" />
      </div>
    </div>

    <!-- Modal -->
    <div class="modal-overlay-figure"></div>
    <div class="modal-figure" v-if="figures.length">
      <!-- Modal Content -->
      <div class="modal-wrap-content-figure py-5">
        <!-- Modal Close -->
        <div class="modal-close-figure mx-5">
          <header class="header d-flex align-items-center justify-content-end">
            <a
              @click.prevent="closeModal"
              class="cursor-pointer d-flex align-items-center"
            >
              <span data-en="CLOSE" data-id="TUTUP">CLOSE</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="ml-2"
              >
                <path
                  d="M15 1L1 15M1 1L15 15"
                  stroke="white"
                  stroke-width="2.4"
                />
              </svg>
            </a>
          </header>
        </div>

        <div class="wrap-container">
          <div class="container">
            <div class="row">
              <div class="d-none d-md-block col-md-4 col-lg-3">
                <div class="modal-navigation sticky-top">
                  <div>
                    <a
                      href="#about-figure"
                      class="d-block mb-2"
                      data-en="About Figure"
                      data-id="Tentang Tokoh"
                      >About Figure</a
                    >
                    <a
                      href="#achievment-figure"
                      class="d-block mb-2"
                      data-en="Achievment Figure"
                      data-id="Pencapaian Tokoh"
                      >Achievment Figure</a
                    >
                    <a
                      href="#contribution-figure"
                      class="d-block mb-2"
                      data-en="Contribution Figure"
                      data-id="Kontribusi Tokoh"
                      >Contribution Figure</a
                    >
                    <a
                      href="#invention-figure"
                      class="d-block mb-2"
                      data-en="Invention Figure"
                      data-id="Penemuan Tokoh"
                      >Invention Figure</a
                    >
                  </div>
                </div>
              </div>

              <div class="col">
                <div id="modal-content-figure" class="modal-content-figure">
                  <img
                    v-lazy="`${urlImg}${figureActive.figure_avatar}`"
                    :alt="figureActive.figure_name"
                    class="modal-thumbnail"
                  />

                  <div
                    class="
                      breadcrumb-modal
                      mt-4
                      mb-3
                      d-flex
                      align-items-center
                      text-uppercase
                    "
                  >
                    <a @click.prevent="closeModal" class="cursor-pointer"
                      >FIGURES</a
                    >
                    <svg
                      width="9"
                      height="8"
                      viewBox="0 0 9 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mx-2"
                    >
                      <path d="M1 1L4 4L1 7" stroke="#101010" />
                      <path d="M5 1L8 4L5 7" stroke="#101010" />
                    </svg>

                    {{ figureActive.figure_name }}
                  </div>

                  <h3 id="about-figure" class="text-uppercase">
                    <span data-en="WHO IS" data-id="SIAPA">WHO IS</span>
                    {{ figureActive.figure_name }}?
                  </h3>

                  <!-- About -->
                  <p
                    :data-en="figureActive.about_en"
                    :data-id="figureActive.about_id"
                    v-html="figureActive.about_en"
                  ></p>

                  <h4
                    data-en="ACHIEVMENTS"
                    data-id="PENCAPAIAN"
                    id="achievment-figure"
                    class="mt-4"
                  >
                    ACHIEVMENTS
                  </h4>

                  <!-- Achievment -->
                  <p
                    :data-en="figureActive.achievment_en"
                    :data-id="figureActive.achievment_id"
                    v-html="figureActive.achievment_en"
                  ></p>

                  <h4
                    data-en="CONTRIBUTION"
                    data-id="KONTRIBUSI"
                    id="contribution-figure"
                    class="mt-4"
                  >
                    CONTRIBUTION
                  </h4>

                  <!-- Contribution -->
                  <p
                    :data-en="figureActive.contribution_en"
                    :data-id="figureActive.contribution_id"
                    v-html="figureActive.contribution_en"
                  ></p>

                  <h4
                    data-en="INVENTION"
                    data-id="PENEMUAN"
                    id="invention-figure"
                    class="mt-4"
                  >
                    INVENTION
                  </h4>

                  <!-- Invention -->
                  <p
                    :data-en="figureActive.invention_en"
                    :data-id="figureActive.invention_id"
                    v-html="figureActive.invention_en"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Toggle Mobile -->
    <ToggleMobile />

    <!-- Help Button -->
    <HelpButton isBlack />
  </div>
</template>

<script>
// Library
import { mapState } from "vuex";
import { directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

// Layouts
import Navbar from "@/components/layouts/Navbar.vue";
import Sidebar from "@/components/layouts/Sidebar.vue";
import Footer from "@/components/layouts/Footer.vue";

// Components
import Parallax from "@/components/components/Parallax.vue";
import LinkArrow from "@/components/components/LinkArrow.vue";
import ToggleMobile from "@/components/components/ToggleMobile.vue";
import TutorialSlide from "@/components/components/TutorialSlide";
import HelpButton from "@/components/components/HelpButton.vue";

// Services
import initParallax from "@/services/parallax/InitParallax.js";
import { getFigures } from "@/services/admin/Figures.js";
import { initLanguage } from "@/services/Language.js";

export default {
  name: "Figures",
  metaInfo: {
    title: "Figures - Space Walk",
    description:
      "Space Walk is a website that is intended for people who have interests and talents in astronomy, with some basic knowledge, it is hoped that many people will start to be interested in astronomy.",
  },
  components: {
    Navbar,
    Sidebar,
    Footer,
    Parallax,
    LinkArrow,
    ToggleMobile,
    TutorialSlide,
    HelpButton,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    ...mapState("tutorial", {
      figure: (state) => state.figure,
    }),
  },
  data() {
    return {
      urlImg: process.env.VUE_APP_STORAGE_URL,
      figures: [],
      figureActive: {},
      swiperFiguresOptions: {
        // loop: true,
        grabCursor: true,
        centeredSlides: true,
        speed: 500,
        slidesPerView: 1,
      },
    };
  },
  mounted() {
    initParallax();
    this.getData();

    if (!this.figure) {
      document.querySelector(".tutorial").classList.toggle("active");
    }
  },
  methods: {
    figureChanged(swiper) {
      const index = swiper.realIndex;
      this.figureActive = this.figures[index];

      setTimeout(() => {
        initLanguage();
      }, 100);
    },
    openModal(i) {
      this.figureActive = this.figures[i];
      this.toggleModal();
    },
    closeModal() {
      this.toggleModal();
    },
    toggleModal() {
      const modalOverlay = document.querySelector(".modal-overlay-figure");
      const modal = document.querySelector(".modal-figure");

      modalOverlay.classList.toggle("active");
      modal.classList.toggle("active");
    },
    getData() {
      getFigures().then((response) => {
        this.figures = response.data.body.data.figures;
        this.figureActive = this.figures[0];

        setTimeout(() => {
          initLanguage();
        }, 100);
      });
    },
  },
};
</script>