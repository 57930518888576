import axios from "axios";
import headers from "./Headers";

function getFigures() {
  return axios.get("figures");
}

function createFigure(form) {
  headers.contentType = "multipart/form-data";
  return axios.post("figures", form, headers);
}

function showFigure(id) {
  return axios.get(`figures/${id}`, headers);
}

function editFigure(id, form) {
  headers.contentType = "multipart/form-data";
  return axios.patch(`figures/${id}`, form, headers);
}

function deleteFigure(id) {
  return axios.delete(`figures/${id}`, headers);
}

export { getFigures, createFigure, showFigure, editFigure, deleteFigure };
